import React from 'react';
import { Container, CustomIFrame } from './styles';

const PageCybersecurity = () => {
  return (
    <Container id='cybersecurity-container'>
      <CustomIFrame
        id='cybersecurity-iframe'
        src='http://fwngp.vtec.net.br:81/v2/guardian/logs/networks'
        title='Cybersecurity Information'
      ></CustomIFrame>
    </Container>
  );
};

export default PageCybersecurity;
